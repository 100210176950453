import * as React from "react"
import Layout from '../components/layout'


// markup
const Index = () => {
  return (
    <Layout>
      <div className="container-xl">
        <h1>Administrador Libro Digital CASSPJS</h1>
      </div>
    </Layout>
  )
}

export default Index
